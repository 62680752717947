:root {
  --primary:  color: rgb(214, 214, 214);
}

.btn {
  /* button size */
  min-width: 150px;
  min-height: 50px;
  max-height: 50px;
  max-width: 350px;
  margin: 0px;
  padding: 3px;
  padding-left: 25px;
  padding-right: 25px;

  font-family: "Roboto", sans-serif;

  cursor: pointer;

  /* Outlline styling  
  border-style: none;
  border: none;
*/

  background-color: rgb(0, 110, 255);
  border: none;
  box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);

  border-color: rgb(0, 119, 255);
  border-width: 2px;
  border-radius: 100px;

  /*Animation*/
  transform: translateY(-7px);
  transition: transform 0.3s ease;

}
.btnOutline {
  /* button size */
  min-width: 150px;
  min-height: 50px;
  max-height: 10px;
  max-width: 350px;
  margin: 0px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;

  font-family: "Roboto", sans-serif;

  cursor: pointer;

  /* Outlline styling  
  border-style: none;
  border: none;
*/
border-style:solid;
background-color: #00000000;
border-color: rgb(0, 119, 255);
border-width: 2.5px;
border-radius: 100px;

  box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.1);

  /*Animation*/
  transform: translateY(-7px);
  transition: transform 0.3s ease;

}

.btnOutline:hover {

  
  border-color: rgb(0, 119, 255);
  border-width:2px;


  transform: translateY(-7px);
  background-color: #004a86;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.3);

}

.btn:hover {

  
  border-color: rgb(0, 119, 255);
  border-width:2px;


  transform: translateY(-7px);
  background-color: #004a86;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.3);

}

/*Animations*/
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}


/* Img and Text */
.container {
  text-align: center;
    margin: 0px;
    justify-content: space-between;
}

.item {
  display:inline-block;
  color: rgb(240, 240, 240);
  text-align: center;

  font-size: 20px;
  margin: 0px;
  font-weight:400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.image {
max-height: 40px;

}
