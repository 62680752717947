:root {
  --sidebarWidth: 400px;
}

.left {
  width: var(--sidebarWidth);
  position: absolute;
}

.right {
  margin-left: var(--sidebarWidth);
}

.sidebar {
  margin-left: 40px;
  background-color: rgb(19, 19, 19);

  width: var(--sidebarWidth);
  padding: 30px;
  position: absolute;
  box-shadow: 5px 5px 10px 2px rgb(0 0 0 / 30%);
  height: 100%;
}

.aboutBorder {
  text-align: center;

  padding: 40px;
  padding-top: 10px;
}

.aboutTitle {
  font-size: 20px;
  text-align: left;
  padding-left: 20px;

}

.aboutName {
  color: rgb(38 132 255);
 
  text-align: center;
}

.aboutText {
  padding-left: 20px;
  font-size: 16px;
 
  text-align: "center";
}

.aboutL h4 {
  font-size: 10px;
}

.sidebarSticky {
  margin-left: 40px;
  background-color: rgb(25, 25, 25);
  width: var(--sidebarWidth);
  padding: 20px;
  position: absolute;
  box-shadow: 5px 5px 10px 2px rgb(0 0 0 / 30%);
  height: 100%;

  height: 100%;

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  overflow-x: hidden;
  padding-top: 20px;

  animation: 0.25s ease-out 0s 1 slideInFromLeft;
}

/*Animations*/
@keyframes slideInFromLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.roundImgProfile {
  border-radius: 200px;
  box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.2);
}

.returnButton {
  position: fixed;

  bottom: 0px; /* matches height of footer */
  left: 0px;
  padding: 30px;
}
.newReturnButton {
  background-color: rgb(0, 119, 255);
  border-color: rgb(0, 119, 255);
  border-radius: 50px;
  min-height: 50px;
  min-width: 50px;
}

/*Youtube*/
.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 20px;
}

.videoResponsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/**/

/*Post Image Component*/

.postImg {
}

.postImg:hover {
  color: aqua;
}

.normal {
}

.zoom {
  background-color: rgb(0, 170, 71);
}

.fullscreenContainer {
  background-color: blue;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fullscreenImg {
  position: inherit;
  top: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
}

/**/

/*FOOTER*/

.footerDiv {
  /*
  position: fixed;
  left: 0;
  bottom: 0;
  width: 80%;
  background-color: red;
  color: white;
  text-align: center;*/
}
