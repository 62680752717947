
.content {
  padding: 0px;
  padding-left: 0px;
  padding-top: 30px;
  padding-right: 10px;
  
}

/*extra
.container {
  padding-top: 10vh;

}
/*
.verticalCenter {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
*/
.size{
/* If weird padding, add  this
  width: 450px;
  height: 240px;
*/

}
  
  /*GRID TILE BUTTONS*/
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 450px); /* width of elements here */
    grid-auto-rows: 240px; /* height here */
  
    grid-gap: 40px;
    justify-content: center; /* this will do the magic */

    margin-left: 450px;
    
  }
  

  
  @media screen and (max-width: 1000px) {
    ul {
      grid-template-columns: repeat(auto-fit, 100%);
      margin: 10px;
    
      grid-auto-rows: auto;
   

    
     
    }

    .size{width: 100%;
      height: 100%;
   
    }

    ol, ul {
      padding-left: 0rem;
 }

 .content {
  padding: 0px;
  padding-left: 0px;
  padding-top: 30px;
  padding-right: 0px;
}

  }


 
  


 
  
  .aboutBtnContainer {

   

      display: flex;
      align-items: center;

  }
  .roundImg {
    border-radius: 200px;
    box-shadow: 4px 4px 5px 2px rgba(0, 0, 0, 0.2);
  }

  
  .aboutBtn {


  

    text-align: center;

    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  }

  .fix {
    padding: 0px;
  }