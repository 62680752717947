:root {
  --primary: #473fb900;
  
}

.btn {

  width: 450px;
  height: 256px;
  min-height: 200px;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
 
  cursor: pointer;

  border-style: none;
  border: none;
  padding: 3px;

  transition: transform 0.3s ease;
  background-color:rgba(14, 14, 14, 0);
  animation: 0.4s ease-out 0s 1 slideInFromLeft;
}
.btnSmall {
  width: 100%;
  height: 100px;
 

  font-family: "Roboto", sans-serif;
  font-weight: 400;
 
  cursor: pointer;

  border-style: none;
  border: none;
  padding: 3px;


  transition: transform 0.3s ease;
  background-color:rgba(14, 14, 14, 0);
  animation: 0.4s ease-out 0s 1 slideInFromLeft;
}

.btn:hover {
  border-radius: 22px;
  transform: translateY(-5px);
  background-color: #949494;
  
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    rgb(16, 89, 131),
    rgb(10, 60, 107),
    rgb(15, 106, 158)
  );
}

.btnSmall:hover {
  border-radius: 22px;
  padding: 2px;
  
  transform: translateY(-2px);
  background-color: #949494;
  
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.4);
  background: linear-gradient(
    rgb(16, 89, 131),
    rgb(10, 60, 107),
    rgb(15, 106, 158)
  );
}

/*Animations*/
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Button Overlay */
.overlayItem {
  position: absolute;
  bottom: 0px;


}
.overlay {
  position: relative;
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
 

  max-width: 100%;
  max-height: 100%;

  width: 100%;
  height: 100%;
}

.overlay:hover {
  filter: brightness(0.9);
}


.image {
  border-radius: 20px;
  object-fit: cover;
  height: 200px;

  outline: 0;
  box-shadow: 5px 5px 7px 2px rgba(0, 0, 0, 0.3);
}

.imageSmall {
  border-radius: 20px;
  object-fit: cover;
  border-radius: 20px;

  outline: 0;
  box-shadow: 5px 5px 7px 2px rgba(0, 0, 0, 0.3);
}


.imageHover {

  filter: brightness(20%) blur(1.5px);

  height: 100%;
  object-fit: cover;
}

.imageHoverS {

  filter: brightness(20%) blur(0px);

  height: 100%;
  object-fit: cover;
}


.imageNoHover {
  filter: brightness(1);
  height: 100%;
  object-fit: cover;  
}

.titlebar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-style: none;
  width: 100%;
  height: 40%;
  border-radius: 0px 0px 19px 19px;

  
  background: linear-gradient(
    rgba(14, 14, 14, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.9)
  );
}



.title {

  position: absolute;
  bottom: 0px;
  left: 20px;
  font-size: 30px;

  color: rgba(255, 255, 255, 0.651);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight:600;
  text-transform: uppercase;
}

.title:hover {
  /*color: rgba(240, 240, 240, 0.534);*/
}

@media screen and (max-width: 1000px) {
  .btn {width: 100%;
  }
  .btn {height: 100%;}
  .title { color: rgb(240, 240, 240);}

  .image{
    box-shadow: 5px 5px 10px 3px rgba(32, 32, 32, 0.4);
  }

  .btn:hover {
    transform: translateY(-7px);
    background-color: #949494;
    
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.4);
    background-color: #949494;
    color: aqua;
  }
}

.smallTitle {
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 3px;
  left: 10px;
font-size: 15px;
color: rgba(255, 255, 255, 0.651);
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight:600;
text-transform: uppercase;
}

.hoverText{
  color: rgb(241, 241, 241);
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}