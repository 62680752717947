.loading {

  clip-path: inset(0);
  object-fit: cover;
}
.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

:root {
  padding: 0px;
  margin: 0px;
}