.App {
  background-color:rgb(20,20,20);
  color: #8f8f8f;



}
:root {
background-color:rgb(20,20,20);



}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



h1 {
  color: #3476c2;
  font-size: 50px;
}

h2 {
  color: #616161;
  font-size: 25px;
}
h3 {
  color: rgb(214, 214, 214);
  font-size: 20px;
  font-family:Arial, Helvetica, sans-serif
}


p {
  color: rgb(172, 172, 172);
  text-align: left;
  font-size: 16px;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 45px;
    
  }
}

img{
  border-radius: 20px;
}


.test {
  padding: 20%;
}
/*Text under images in posts*/
.imgText {
  text-align: center;
  font-size: 15px;
  padding: 5px;
  color: #6b6b6b;
}

/*hyperlink styles*/
a:link {
  text-decoration: none;
  color: #3184e4;
  font-weight: 600;

}

a:visited {
  text-decoration: none;
  color: #3184e4;

    
}

a:hover {
  text-decoration: underline;
 
 

}

a:active {
  text-decoration: underline;

}

