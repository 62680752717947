.post {
  margin: 0px;
  margin-left: 440px;
  padding: 20px;
  padding-top: 40px;


  transition: transform 0.3s ease;
  animation: 0.25s ease-out 0s 1 slideInFromLeft;
  
}

.content {
  border-radius: 20px;
  background-color: rgb(32, 32, 32);

  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
  box-shadow: 5px 5px 10px 2px rgb(0 0 0 / 10%);
}

.bar {
  border-radius: 20px;
  background-color: rgb(32, 32, 32);

  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
  box-shadow: 5px 5px 10px 2px rgb(0 0 0 / 10%);
  text-align: center;

}

.titleBar {
  display: flex;
  justify-content: space-between;
}

/*Flex*/
.spaceCenter {
  display: flex;
  justify-content: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}


.spaceEvenly {
  display: flex;
  justify-content: space-evenly;
}



/*Old*/

.image {
  border-radius: 20px;
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.returnButton {
  padding: 35px;
  text-align: left;
  border-radius: 20px;
}

.imageLeft {
  padding: 30px;
  text-align: left;
  float: left;
  border-radius: 20px;
  border-radius: 500px;
}


@media screen and (max-width: 1000px) {
  .post {
    margin-left: 0px;
  
    
  }
  .image {
    width: 100%;
    max-height: none;
  }
  .content {
    background-color: rgba(32, 32, 32, 0);
    box-shadow: none;
    padding: 0px;
  }
}

@media screen and (max-width: 1400px) {
  .post {
    padding: 5%;
  }
}

.head {
  text-align: left;
  padding: 20px;
}

.sidebarReturn {
  text-align: left;
  padding-top: 10px;
}




/*Title and buttons*/

.hContainer {
  
}

.hTitle {
  text-align: left;

}

.hButton {

  text-align: right;
}

@media screen and (max-width: 1000px) {
  .hTitle{
   text-align: center;
   padding-bottom: 10px;
  }
  .hButton {
    padding: 0px;

    text-align: center;
  }

  .aboutHeader {
   text-align: center;
   display: none;
   justify-content:unset;
   flex-direction: column;




flex-wrap: wrap;
align-content: center;
justify-content: flex-end;
align-items: normal;
  }
  
}





/*Animations*/
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}


/* New Shit */

.centerDiv {
  text-align: center;
  width: 100%;
}

/*text list currently in about page*/

.list {
  font-size: 20px;

}

.list ul {
  grid-gap: 0px;

}

.aboutHeader {

  display: flex;
  justify-content: space-between;
}