
/*About Page*/

.aboutMe {
  text-align: left;
}
.workExperience {

  color: rgb(119, 119, 119);

text-align: left;
}

.workExperienceText {

  color: rgb(180, 180, 180);

text-align: left;
}




/*sidebar*/
.pageBtnSmall {


}

.software {
  padding-top: 10px;
}