

:root {
    --sidebarWidth: 400px;
  }
  

  .textOffset {
  color: rgb(194, 194, 194);
  margin:5px;
  padding-top: 10px;
 
  text-align: left;
  font-size: 15px;

  }

  .simage {
    color: aquamarine;
    width: 50px;
    height: 50px;
    padding: 4px;

    }

    .leftElement {
      margin: 5px;
      width: 45%;
      color: aquamarine;

      text-align: left;
      }

    

      
    
      .row {
        margin-bottom: 20px;  
  
      }
    

      .other {
   
      }
      .portfolio {
        display: inline-block;
        float: center;
        
        background-color: rgb(22, 22, 22);
        border-radius: 25px;
        padding: 0px;

      }



      .btn {

    
        width: 50%;
        padding: 5px;
        display: inline-block;
        text-align: left;
      
     
        
      }

      .h4 {
        font-size: 10px;
      }